<template>
  <div class="orderHistory-box rounded-xl py-4 my-4">
    <v-row justify="space-between" no-gutters class="px-4">
      <v-col cols="12" sm="6">
        <table class="orderHistory-info-table d-block">
          <tr v-if="detail.cancellationReferenceNumber" class="d-sm-table-row d-block">
            <td class="history-table-left-info d-sm-table-cell d-block">
              <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.refundId`) }}</p>
            </td>
            <td class="d-sm-table-cell d-block">
              <p class="mb-0 text-sm-body-1 text-body-2">{{ detail.cancellationReferenceNumber }}</p>
            </td>
          </tr>
          <tr class="d-sm-table-row d-block">
            <td class="history-table-left-info d-sm-table-cell d-block">
              <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.orderId`) }}</p>
            </td>
            <td class="d-sm-table-cell d-block">
              <p class="mb-0 text-sm-body-1 text-body-2">{{ detail.orderReferenceNumber }}</p>
            </td>
          </tr>
          <tr v-if="detail.trackingNumber != null" class="d-sm-table-row d-block">
            <td class="history-table-left-info d-sm-table-cell d-block">
              <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.trackingNumber`) }}</p>
            </td>
            <td class="d-sm-table-cell d-block">
              <a :href="`https://t.17track.net/en#nums=${detail.trackingNumber}`" target="_blank" class="mb-0 text-sm-body-1 text-body-2">
                {{ `${detail.courierName} - ${detail.trackingNumber}` }}
              </a>
              <v-btn class="backgroundColor--white" elevation="0" tile icon @click="copyTrackingNumber()">
                <v-icon class="stroke--light_gray" v-text="'$copyIcon'"></v-icon>
              </v-btn>
            </td>
          </tr>
          <tr class="d-sm-table-row d-block">
            <td class="history-table-left-info d-sm-table-cell d-block">
              <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.orderDate`) }}</p>
            </td>
            <td class="d-sm-table-cell d-block">
              <p class="mb-0 text-sm-body-1 text-body-2">{{ $shared.getDateFormat(detail.createdDateTime) }}</p>
            </td>
          </tr>
        </table>
      </v-col>
      <v-col cols="12" sm="6">
        <table class="orderHistory-info-table text-sm-right text-center">
          <tr>
            <td>
              <p class="mb-0 py-2 my-sm-0 text-sm-body-1 text-body-2 font-weight-bold" :class="getStatusColor(detail.orderStatus)">
                {{ historyOrderStatus }}
              </p>
            </td>
          </tr>
          <tr v-if="!isPayment && (detail.orderStatus == 'pendingsubmission' || detail.orderStatus == 'pendingfulfilment' || detail.orderStatus == 'success')">
            <td>
              <div v-if="detail.orderStatus == 'pendingsubmission'">
                <v-btn outlined color="red" dense class="text-capitalize rounded-lg d-none d-sm-inline-block" @click="openConfirmationDialog(detail, 'cancel')">
                  {{ $t(`label.cancelOrder`) }}
                </v-btn>
                <v-btn block outlined color="red" class="text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6 font-weight-bold" @click="openConfirmationDialog(detail, 'cancel')">
                  {{ $t(`label.cancelOrder`) }}
                </v-btn>
              </div>
              <div v-else-if="detail.orderStatus == 'pendingfulfilment' && withinRefundPolicyRefundDay(detail) && detail.trackingNumber == null">
                <v-btn outlined color="red" dense class="text-capitalize rounded-lg d-none d-sm-inline-block" @click="openConfirmationDialog(detail, 'void')">
                  {{ $t(`label.cancelOrder`) }}
                </v-btn>
                <v-btn block outlined color="red" class="text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6 font-weight-bold" @click="openConfirmationDialog(detail, 'void')">
                  {{ $t(`label.cancelOrder`) }}
                </v-btn>
              </div>
              <div v-else-if="detail.orderStatus == 'success' && withinRefundPolicyRefundDay(detail) && detail.transactionType.toLowerCase() != 'preorder'">
                <v-btn outlined color="red" dense class="text-capitalize rounded-lg d-none d-sm-inline-block" @click="openConfirmationDialog(detail, 'refund')">
                  {{ $t(`label.refund`) }}
                </v-btn>
                <v-btn block outlined color="red" dense class="text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6" @click="openConfirmationDialog(detail, 'refund')">
                  {{ $t(`label.refund`) }}
                </v-btn>
              </div>
              <div v-else-if="detail.orderStatus == 'success' && detail.transactionType.toLowerCase() == 'preorder'">
                <v-btn outlined color="red" dense class="text-capitalize rounded-lg d-none d-sm-inline-block" @click="openConfirmationDialog(detail, 'cancelSuccessOrder')">
                  {{ $t(`label.cancelOrder`) }}
                </v-btn>
                <v-btn
                  block
                  outlined
                  color="red"
                  class="text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6 font-weight-bold"
                  @click="openConfirmationDialog(detail, 'cancelSuccessOrder')"
                >
                  {{ $t(`label.cancelOrder`) }}
                </v-btn>
              </div>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <v-divider class="my-4 orderHistory-info-divider"></v-divider>
    <v-row
      v-for="(orderItem, index) in isCompletedTab ? detail.orderItems.filter((item) => item.order_statuses_name.toLowerCase() != 'exchanged') : detail.orderItems"
      justify="space-between"
      no-gutters
      class="px-4"
      :key="`${detail.referenceNumber}_${index}_${orderItem.product_display_sku}`"
      :class="isPayment ? '' : index >= 1 ? 'd-none' : ''"
    >
      <v-col cols="12" sm="auto" class="text-sm-left text-center">
        <table class="orderHistory-info-table">
          <tr>
            <td class="history-table-left-info product-image d-block d-sm-table-cell mx-auto mr-sm-0">
              <img class="full-width history-product-image-box rounded-lg" :src="`${shared.MEDIA_SERVER_URL}/products/variants/${orderItem.product_display_sku}.png`" alt="" />
            </td>
            <td class="d-block d-sm-table-cell">
              <p class="text-body-1 font-weight-bold my-1">{{ orderItem.product_name }}</p>
              <p v-if="orderItem.service_pin" class="text-body-2 font-weight-medium">
                {{ $t('label.pin') }}: {{ orderItem.service_pin }}
                <v-btn small class="ml-2 backgroundColor--white" elevation="0" tile icon @click="copyServicePin(orderItem.service_pin)">
                  <v-icon small class="stroke--light_gray" v-text="'$copyIcon'"></v-icon>
                </v-btn>
              </p>
              <p class="text-sm-body-1 text-body-2 my-1">
                {{ getProductVariantDisplay(orderItem.product_variants) }}
              </p>
              <p class="unbox_red--text font-weight-bold text-sm-body-1 text-body-2 my-1">{{ `${$shared.formatCurrency(orderItem.selling_price)}` }}</p>
            </td>
          </tr>
        </table>
      </v-col>
      <v-col cols="12" sm="auto" class="text-sm-left text-center">
        <span class="text-sm-body-1 text-body-2">{{ `${$t(`label.quantity`)}: ` }}</span>
        <span class="text-h6 font-weight-bold mb-2">{{ `x${orderItem.quantity}` }}</span>
      </v-col>
    </v-row>
    <v-divider class="my-4 orderHistory-info-divider"></v-divider>
    <v-row justify="space-between" no-gutters class="px-4">
      <v-col cols="12" sm="6">
        <table class="orderHistory-info-table">
          <tr>
            <td class="history-table-left-info">
              <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.totalItems`) }}</p>
            </td>
            <td class="text-right text-sm-left">
              <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">
                {{ `${detail.orderItems.filter((item) => item.order_statuses_name.toLowerCase() != 'exchanged').length} ${$t(`label.items`)}` }}
              </p>
            </td>
          </tr>
          <tr v-if="detail.discountCode != null">
            <td class="history-table-left-info">
              <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.promoCode`) }}</p>
            </td>
            <td class="text-right text-sm-left">
              <p class="mb-0 text-sm-body-1 text-body-2">{{ detail.discountCode }}</p>
            </td>
          </tr>
          <tr>
            <td class="history-table-left-info">
              <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ isProcessingTab ? $t('label.refundTotal') : $t(`label.orderTotal`) }}</p>
            </td>
            <td class="text-right text-sm-left">
              <p class="mb-0 unbox_red--text font-weight-bold text-sm-body-1 text-body-2">{{ `${$shared.formatCurrency(detail.purchasePrice)}` }}</p>
            </td>
          </tr>
        </table>
      </v-col>
      <v-col cols="12" sm="6">
        <table class="orderHistory-info-table text-right">
          <tr>
            <td>
              <div v-if="!isPayment">
                <v-btn outlined color="#64748B" dense class="text-capitalize rounded-lg d-none d-sm-inline-block" @click="viewDetail(detail)">{{ $t(`label.viewDetails`) }}</v-btn>
                <v-btn height="50" outlined color="#64748B" block class="font-weight-bold text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6" @click="viewDetail(detail)">
                  {{ $t(`label.viewDetails`) }}
                </v-btn>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div v-if="!isPayment && detail.orderStatus !== 'pendingsubmission' && detail.receiptPath !== undefined">
                <v-btn class="d-none d-sm-inline-block backgroundColor--white" elevation="0" tile icon @click="getReceipt()">
                  <v-icon v-text="'$downloadIcon'"></v-icon>
                </v-btn>
                <v-btn
                  block
                  height="50"
                  color="black"
                  class="font-weight-bold text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6 white--text"
                  elevation="0"
                  tile
                  @click="getReceipt()"
                >
                  {{ $t(`label.downloadReceipt`) }}
                </v-btn>
              </div>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <div v-if="detail.orderStatus == 'pendingsubmission'">
      <v-divider class="my-4 orderHistory-info-divider"></v-divider>
      <v-row justify="space-between" no-gutters class="px-4">
        <v-col cols="12">
          <p class="font-weight-bold mb-0 text-body-2">{{ $t(`label.toPayTimerRemark`) }}</p>
        </v-col>
      </v-row>
    </div>

    <!-- additional info dialog for regenerating receipt  -->
    <app-dialog :dialogShow="additionalInfoDialogShow" :title="$t(`label.additionalInfoRequired`)" :closeAction="() => (additionalInfoDialogShow = !additionalInfoDialogShow)" :max-width="500">
      <template v-slot:body>
        <v-container>
          <v-form ref="additionalInfoForm" @submit.prevent="regenerateReceipt(additionalInfo)">
            <v-row>
              <v-col cols="12">
                <div class="text-body-2 unbox_secondary--text">{{ $t('message.additionalInfoRequired') }}</div>
              </v-col>
              <v-col cols="12">
                <app-form-field v-model.trim="additionalInfo.email" :placeholder="$t(`label.email`)" :label="$t(`label.email`)" :rules="$validation.emailRules()"></app-form-field>
              </v-col>
              <v-col cols="12">
                <app-form-field
                  v-model.trim="additionalInfo.emailPhoneNumber"
                  :placeholder="$t(`label.phoneNumber`)"
                  :label="$t(`label.phoneNumber`)"
                  :rules="$validation.requiredRules()"
                ></app-form-field>
              </v-col>
              <v-col cols="12">
                <app-form-field
                  v-model.trim="additionalInfo.emailBillingAddress"
                  :placeholder="$t(`label.billingAddress`)"
                  :label="$t(`label.billingAddress`)"
                  :rules="$validation.requiredRules()"
                ></app-form-field>
              </v-col>
              <v-col cols="12">
                <app-form-field
                  v-model.trim="additionalInfo.emailCustomerName"
                  :placeholder="$t(`label.name`)"
                  :label="$t(`label.name`)"
                  :rules="$validation.requiredRules()"
                  readonly
                ></app-form-field>
              </v-col>
              <v-col cols="12">
                <div class="text-center">
                  <v-btn block class="white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8" height="56px" type="submit" color="unbox_black" elevation="0">
                    {{ $t(`label.confirm`) }}
                  </v-btn>
                </div>
                <div class="text-center">
                  <v-btn
                    text
                    class="black--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8"
                    @click="additionalInfoDialogShow = !additionalInfoDialogShow"
                    height="56px"
                    block
                    color="unbox_black"
                    elevation="0"
                  >
                    {{ $t(`label.cancel`) }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
    </app-dialog>
  </div>
</template>

<script>
import { DELIVERY_METHOD, SHARED } from '@/constants'
import { ORDER_RESEND_RECEIPT } from '@/store/order.module'
import { SHARED_CLOSE_DIALOG, SHARED_SHOW_DIALOG } from '@/store/shared.module'

export default {
  props: {
    detail: {
      type: Object,
      required: true,
      default: null
    },
    viewDetail: {
      type: Function,
      required: false,
      default: () => ({})
    },
    openConfirmationDialog: {
      type: Function,
      required: false,
      default: () => ({})
    },
    isPayment: {
      type: Boolean,
      required: false,
      default: false
    },
    isProcessingTab: {
      type: Boolean,
      default: false
    },
    isCompletedTab: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {},
  data: () => ({
    shared: SHARED,
    deliveryMethod: DELIVERY_METHOD,
    additionalInfoDialogShow: false,
    additionalInfo: {
      email: '',
      uuid: '',
      emailPhoneNumber: '',
      emailBillingAddress: '',
      emailCustomerName: ''
    }
  }),
  computed: {
    customerInfo() {
      return this.$store.state.customer.info
    },
    historyOrderStatus() {
      if (this.isProcessingTab) {
        const processingTabRules = [
          { rule: (orderStatus, isApproved) => orderStatus.toLowerCase() === 'submitted' && !isApproved, value: this.$t('label.pendingApproval') },
          { rule: (orderStatus, isApproved) => orderStatus.toLowerCase() === 'pendingfulfilment' && isApproved, value: this.$t('label.pendingRefund') },
          { rule: () => true, value: this.$t(`historyRefundOrderStatus.${this.detail.orderStatus}`) }
        ]
        return processingTabRules.find(({ rule }) => rule(this.detail.orderStatus, this.detail.isApproved)).value
      }
      return this.detail.orderStatus.toLowerCase() == 'tobereceive' && this.detail.deliveryMethodName.toLowerCase() == this.deliveryMethod.SELF_PICKUP
        ? this.$t(`historyOrderStatus.${this.detail.orderStatus}_pickup`)
        : this.$t(`historyOrderStatus.${this.detail.orderStatus}`)
    }
  },
  methods: {
    getReceipt() {
      if (this.detail.receiptPath) {
        const link = `${SHARED.MEDIA_SERVER_URL}/${this.detail.receiptPath}`
        window.open(link, '_blank')
      } else {
        const { uuid, email, emailPhoneNumber, emailBillingAddress, emailCustomerName } = this.detail
        const hasMissingFields = uuid == null || email == null || emailPhoneNumber == null || emailBillingAddress == null || emailCustomerName == null
        if (hasMissingFields) {
          let fallbackAddress = ''
          let fallbackCustomerName = `${this.customerInfo.firstName} ${this.customerInfo.lastName}`
          const billingInfo = this.customerInfo.addresses.length > 0 && this.customerInfo.addresses.find((addr) => addr.isDefaultBilling)
          if (billingInfo !== undefined) {
            const { address_1 = '', address_2 = '', address_3 = '', city = '', postcode = '', state = '', display_name } = billingInfo
            fallbackAddress = `${address_1} ${address_2} ${address_3} ${city} ${postcode} ${state}`.replace(/ +/g, ' ')
          }
          this.additionalInfo = {
            uuid: uuid,
            email: email ?? this.customerInfo.email,
            emailPhoneNumber: emailPhoneNumber ?? `${this.customerInfo.phoneCode}${this.customerInfo.phone}`,
            emailBillingAddress: emailBillingAddress ?? fallbackAddress,
            emailCustomerName: emailCustomerName ?? fallbackCustomerName
          }

          this.additionalInfoDialogShow = true
        } else {
          this.regenerateReceipt(this.detail)
        }
      }
    },
    getStatusColor(status) {
      if (this.isProcessingTab) return 'unbox_primary--text'
      switch (status.toLowerCase()) {
        case 'pendingsubmission':
          return 'hint_color--text'
        case 'cancelled':
          return 'unbox_primary--text'
        case 'failed':
        case 'expired':
          return 'red--text'
        default:
          return 'success_01--text'
      }
    },
    withinRefundPolicyRefundDay(detail) {
      let allowRefund = true
      let orderPaymentMadeDateInfo = detail.orderStatusHistory.find(
        (x) => x.from_status != null && x.from_status.toLowerCase() == 'pendingsubmission' && x.to_status != null && x.to_status.toLowerCase() == 'pendingfulfilment'
      )

      if (orderPaymentMadeDateInfo == undefined) {
        allowRefund = false
      } else {
        let allowRefundDate = new Date(orderPaymentMadeDateInfo.created_date_time)
        allowRefundDate = allowRefundDate.setDate(allowRefundDate.getDate() + 14)

        let now = new Date()
        if (now > allowRefundDate) {
          allowRefund = false
        }
        return allowRefund
      }
    },
    withinSettlementDay(detail) {
      let todayDate = new Date(
        new Date(detail.createdDateTime.toLocaleString('en-US', { timeZone: 'Asia/Singapore' })).toISOString().slice(0, 10) +
          'T00:00:00.000000Z'.toLocaleString('en-US', { timeZone: 'Asia/Singapore' })
      )
      let settlementCutOffDate = new Date(new Date().toISOString().slice(0, 10) + 'T14:30:00.000000Z'.toLocaleString('en-US', { timeZone: 'Asia/Singapore' }))
      let orderCreatedDate = new Date(detail.createdDateTime.toLocaleString('en-US', { timeZone: 'Asia/Singapore' }))
      if (orderCreatedDate >= todayDate && orderCreatedDate <= settlementCutOffDate) {
        return true
      } else if (orderCreatedDate > settlementCutOffDate) {
        return false
      }
    },
    getProductVariantDisplay(variant) {
      return variant
        .map((o) => {
          return o.value
        })
        .join('/')
    },
    copyTrackingNumber() {
      this.$copyText(this.detail.trackingNumber)
    },
    regenerateReceipt(details) {
      if (this.$refs.additionalInfoForm.validate()) {
        const { uuid, email, emailPhoneNumber, emailBillingAddress, emailCustomerName } = details

        const _buttons = [
          {
            title: this.$t('action.ok'),
            type: 'default',
            action: () => {
              this.$store.dispatch(SHARED_CLOSE_DIALOG)
            }
          }
        ]

        this.$store
          .dispatch(ORDER_RESEND_RECEIPT, {
            data: {
              uuid,
              email,
              billingCustomerName: emailCustomerName,
              billingAddress: emailBillingAddress,
              billingPhoneNumber: emailPhoneNumber
            }
          })
          .then(() => {
            this.$store.dispatch(SHARED_SHOW_DIALOG, {
              messageTitle: this.$t('label.success'),
              messages: [this.$t('label.successResendReceipt')],
              buttons: _buttons
            })
          })
          .catch((message) => {
            this.$store.dispatch(SHARED_SHOW_DIALOG, {
              messageTitle: this.$t('label.fail'),
              messages: [message.error],
              messageErrorCode: message.code,
              buttons: _buttons
            })
          })
          .finally(() => {
            this.additionalInfoDialogShow = false
          })
      }
    },
    copyServicePin(pinCode) {
      this.$copyText(pinCode)
    }
  }
}
</script>

<style lang="scss">
.orderHistory-box {
  border: 2px solid #d5dde0;
}

.history-table-left-info {
  width: 180px;
}

.history-product-image-box {
  border: 1px solid #d5dde0;
  width: 80%;
}

.orderHistory-info-table {
  width: 100%;
  td {
    padding: 5px 0;
  }
}

.orderHistory-info-divider {
  border-top: 2px solid #d5dde0;
}

@media only screen and (max-width: 599px) {
  .history-table-left-info {
    width: auto;
    &.product-image {
      width: 200px;
    }
  }
  .history-product-image-box {
    width: 100%;
  }
}
</style>
